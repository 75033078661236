<template>
  <v-main class="pa-0 ma-0 mt-0">
    <v-col class="my-0">
      <v-simple-table>
        <tbody>
          <tr v-for="(value, title, index) in params" v-bind:key="index">
            <td class="tdTitle">
              <p class="text-body-2" style="font-weight: bold">
                {{ $t(title) }}
              </p>
            </td>

            <td class="tdDesc">
              <v-btn
                v-if="index == 1"
                :ripple="false"
                elevation="0"
                class="text-body-2"
                prepend-icon="mdi-phone"
                @click="callDialog = true"
              >
                {{ $t("shop.call_reserve") }}</v-btn
              >
              <a
                v-else-if="index == 3"
                v-html="value"
                class="text-body-2 mx-2"
                style="font-weight: normal; text-decoration: underline"
                @click="callMap(value)"
              ></a>
              <div v-else-if="index == 5" align="left">
                <iframe
                  :width="isMobileDevice.value ? '250' : '500'"
                  :height="isMobileDevice.value ? '400' : '560'"
                  :src="generateInstaUrl(value)"
                  frameborder="0"
                ></iframe>
              </div>
              <p
                v-else
                v-html="value"
                class="text-body-2 mx-2"
                style="font-weight: normal"
              ></p>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-main>

  <v-dialog v-model="callDialog" width="auto">
    <v-card>
      <v-card-title class="text-h5">{{
        $t("shop.before_making")
      }}</v-card-title>
      <v-card-text>
        {{ $t("shop.pls_tell_store") }}
        <span class="text-h6" style="font-weight: bold">Beauty360</span>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="makeACall">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Uitls from "@/uitls/Uitls.js";

export default {
  props: {
    shopObjects: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({}),
  },

  setup(props) {
    // console.log(props.shopObjects);
  },

  data() {
    return {
      params: {},
      businessHours: null,
      callDialog: false,
    };
  },

  mounted() {
    if (this.shopObjects != null && this.shopObjects.detail != null) {
      this.showListArrange();
    }
  },

  methods: {
    showListArrange() {
      this.params["shop.shop_name"] = this.shopObjects["name"];
      this.params["shop.tel"] = this.shopObjects.detail["phone"];
      this.params["shop.business"] = this.handleShopBusinessHour();
      this.params["shop.address"] = this.shopObjects.detail["address"];

      if (this.shopObjects.detail["is_car_parking"] > 0) {
        this.params["shop.carpark"] = "Yes";
      }

      //  this.params["shop.holidays"] = this.shopObjects.detail["shop_holidays"];
      //  this.params["shop.payment"] = this.shopObjects.detail["payment_method"];
      //  this.params["shop.capacity"] = this.shopObjects.detail["seats"];
      //  this.params["shop.staff"] = this.shopObjects.detail["address"];
      //  this.params["shop.sales_point"] = this.shopObjects.detail["sales_point"];

      // if (this.shopObjects.detail["instagram"] != null) {
      //   this.params["shop.other"] = this.shopObjects.detail["instagram"];
      // }
    },

    generateInstaUrl(value) {
      let queryString = value;
      if (queryString.includes("/?hl=en")) {
        queryString = queryString.replace("/?hl=en", "");
      }
      if (queryString.endsWith("/")) {
        queryString = queryString.slice(0, -1); // Remove the last character
      }
      queryString = queryString + "/embed";
      return queryString;
    },

    handleShopBusinessHour() {
      let result = null;

      if (this.shopObjects.schedule_policy) {
        const businessHours = this.shopObjects.schedule_policy;

        var monday = this.$i18n.tc("shop.mon");
        if (Uitls.isEmpty(businessHours.opening_time_1)) {
          monday = monday.concat(" ", this.$i18n.tc("shop.closed"));
        } else {
          monday =
            this.$i18n.tc("shop.mon") +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.opening_time_1)
              )
            ) +
            " ~ " +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.closing_time_1)
              )
            );
        }
        monday = monday + "<br>";

        var tuesday = this.$i18n.tc("shop.tue");
        if (Uitls.isEmpty(businessHours.opening_time_2)) {
          tuesday = tuesday.concat(" ", this.$i18n.tc("shop.closed"));
        } else {
          tuesday =
            this.$i18n.tc("shop.tue") +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.opening_time_2)
              )
            ) +
            " ~ " +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.closing_time_2)
              )
            );
        }
        tuesday = tuesday + "<br>";

        var wednesday = this.$i18n.tc("shop.wed");
        if (Uitls.isEmpty(businessHours.opening_time_3)) {
          wednesday = wednesday.concat(" ", this.$i18n.tc("shop.closed"));
        } else {
          wednesday =
            this.$i18n.tc("shop.wed") +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.opening_time_3)
              )
            ) +
            " ~ " +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.closing_time_3)
              )
            );
        }
        wednesday = wednesday + "<br>";

        var thursday = this.$i18n.tc("shop.thu");
        if (Uitls.isEmpty(businessHours.opening_time_4)) {
          thursday = thursday.concat(" ", this.$i18n.tc("shop.closed"));
        } else {
          thursday =
            this.$i18n.tc("shop.thu") +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.opening_time_4)
              )
            ) +
            " ~ " +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.closing_time_4)
              )
            );
        }
        thursday = thursday + "<br>";

        var friday = this.$i18n.tc("shop.fri");
        if (Uitls.isEmpty(businessHours.opening_time_5)) {
          friday = friday.concat(" ", this.$i18n.tc("shop.closed"));
        } else {
          friday =
            this.$i18n.tc("shop.fri") +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.opening_time_5)
              )
            ) +
            " ~ " +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.closing_time_5)
              )
            );
        }
        friday = friday + "<br>";

        var saturday = this.$i18n.tc("shop.sat");
        if (Uitls.isEmpty(businessHours.opening_time_6)) {
          saturday = saturday.concat(" ", this.$i18n.tc("shop.closed"));
        } else {
          saturday =
            this.$i18n.tc("shop.sat") +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.opening_time_6)
              )
            ) +
            " ~ " +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.closing_time_6)
              )
            );
        }
        saturday = saturday + "<br>";

        var sunday = this.$i18n.tc("shop.sun");
        if (Uitls.isEmpty(businessHours.opening_time_0)) {
          sunday = sunday.concat(" ", this.$i18n.tc("shop.closed"));
        } else {
          sunday =
            this.$i18n.tc("shop.sun") +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.opening_time_0)
              )
            ) +
            " ~ " +
            Uitls.formatAMPM(
              new Date(
                Date.parse("Thu, 01 Jan 1970 " + businessHours.closing_time_0)
              )
            );
        }
        sunday = sunday + "<br>";

        result = monday.concat(
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday
        );
      }
      return result;
    },

    makeACall() {
      this.callDialog = false;
      window.open("tel:" + this.shopObjects.detail["phone"], "_self");
    },
    callMap(add) {
      window.open("https://maps.google.com/?q=" + add, "_self");
    },
  },
};
</script>

<style scoped>
.tdTitle {
  width: 30%;
  background-color: rgba(211, 211, 211, 0.217);
  border: none;
  text-align: left;
  vertical-align: text-top;
  padding: 1.5em;
}

.tdDesc {
  vertical-align: text-top;
  padding: 0.5em;
}
</style>
<template>
  <v-card
    elevation="0"
    color="grey-lighten-5"
    class="pa-2 mt-4 mb-6"
    prepend-icon="mdi-store"
    :title="$t('shop.about_shop_information')"
    subtitle=""
    :text="$t('shop.pls_contact_us')"
  >
    <v-row justify="center" no-gutters>
      <v-btn
        rounded="pill"
        elevation="0"
        size="small"
        width="40%"
        class="text-caption mb-4"
        height="40px"
        prepend-icon="mdi-email"
        :ripple="false"
        :to="{
          name: 'ContactUs',
          params: { type: 'Shop information correction' },
        }"
      >
        {{ $t("shop.contact_us") }}
      </v-btn>
    </v-row>

    <!-- <v-row justify="center" no-gutters class="mt-4">
      <v-col>
        <div align="center">
          <v-btn
            width="80%"
            elevation="0"
            height="50px"
            class="border"
            :ripple="false"
          >
            <span style="font-size: 10px">Hair Style</span>
          </v-btn>
        </div>
      </v-col>

      <v-col>
        <div align="center">
          <v-btn
            width="80%"
            elevation="0"
            height="50px"
            class="border"
            :ripple="false"
          >
            <span style="font-size: 10px">Reviews (5)</span>
          </v-btn>
        </div>
      </v-col>

      <v-col>
        <div align="center">
          <v-btn
            width="80%"
            elevation="0"
            height="50px"
            class="border"
            :ripple="false"
          >
            <span style="font-size: 10px">Photos (30)</span>
          </v-btn>
        </div>
      </v-col>
    </v-row> -->
  </v-card>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <v-card
    class="event-card mb-0"
    height="140"
    :ripple="false"
    @click="articleDetailOnClickBySlug()"
  >
    <v-layout row>
      <v-img
        width="140"
        max-width="140"
        cover
        :src="
          articleObjects.better_featured_image
            ? articleObjects.better_featured_image.source_url
            : no_IMAGE
        "
        :lazy-src="no_IMAGE"
        aspect-ratio="1"
      />
      <v-layout column justify-space-between class="flex-grow-1">
        <v-row class="mx-0 mt-n4">
          <v-col cols="12">
            <v-card-actions>
              <p class="text-body-2">
                <v-icon
                  icon="mdi-bookmark"
                  color="#8fc31f"
                  size="18px"
                ></v-icon>
                {{
                  $t("utls." + getCategoryName(articleObjects.categories[0]))
                }}
              </p>
            </v-card-actions>

            <div
              class="text-body-1 mt-0"
              v-snip="{ lines: 3 }"
              style="color: rgb(51, 51, 51)"
            >
              {{ articleObjects.title.rendered }}
            </div>
            <p class="bottom-right text-caption">
              {{ extractYearAndMonth(articleObjects.date) }}
            </p>
          </v-col>
        </v-row>
      </v-layout>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import utils from "@/uitls/Uitls";

export default {
  props: {
    articleObjects: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {},

  computed: {
    ...mapState({
      getArticleCategory: (state) => state.ArticleStore.articleCategiry,
    }),
  },

  data() {
    return {
      local_logo: require("@/assets/img/logo120.png"),
    };
  },

  methods: {
    getCategoryName(item) {
      return utils.categoryMappingResult(item, this.getArticleCategory);
    },

    articleDetailOnClick(articleID) {
      this.$emit("articleIDCallBack", articleID);
    },
    articleDetailOnClickBySlug() {
      this.$emit("articleDetailOnClickBySlug", this.articleObjects.slug);
    },

    extractYearAndMonth(value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
      const day = date.getDate(); // Use getDate() to get the day of the month

      // Format with leading zeros for single-digit day/month
      return `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
    },
  },
};
</script>

<style scoped>
.bottom-right {
  position: absolute;
  bottom: 0px;
  right: 0;
  padding: 0.3em; /* Adjust as needed */
}
</style>
<template>
  <v-sheet elevation="0" class="mt-12">
    <v-slide-group class="">
      <v-card
        class="ma-2"
        max-width="344"
        v-for="item in brandList"
        :key="item"
        @click="getLorealTag(item.tag)"
      >
        <v-img
          max-height="100%"
          :width="isMobileDevice.value ? 320 : 480"
          :src="item.image"
          cover
        ></v-img>

        <p class="text-body-2 pa-4">
          {{ $t(item.desc) }}
        </p>
      </v-card>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import Uitls from "@/uitls/Uitls";

export default {
  data() {
    return {
      brandList: [
        {
          title: "brand.loreal_title",
          desc: "brand.loreal_desc",
          tag: "utls.loreal",
          image: "./banner_loreal.jpg",
        },
        {
          title: "brand.shiseido_title",
          desc: "brand.shiseido_desc",
          tag: "utls.shiseido",
          image: "./banner_shiseido.jpg",
        },
        {
          title: "brand.kerastase_title",
          desc: "brand.kerastase_desc",
          tag: "utls.kerastase",
          image: "./banner_kerastase.jpg",
        },

        
      ],
      tagList: Uitls.GPTSummaryTags(),
    };
  },

  methods: {
    getLorealTag(tag) {
      var index = Uitls.GPTSummaryTags().indexOf(tag);
      this.$emit("brandListCallBack", index);
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <v-sheet color="white">
    <br />
    <v-list-item class="mt-0">
      <template v-slot:prepend>
        <v-img src="/magazine.png" :width="30"></v-img>
      </template>
      <div class="d-flex align-center justify-space-between">
        <h2 class="text-h5 mx-2" style="font-weight: bold">
          {{ $t("homeview.new_topics") }}
        </h2>
      </div>
    </v-list-item>

    <v-container>
      <v-row>
        <v-col
          v-for="item in list"
          :key="item.id"
          cols="12"
          md="6"
          xs="12"
          sm="12"
        >
          <ArticleTableCellCard
            :articleObjects="item"
            @articleIDCallBack="articleIDCallBack"
            @articleDetailOnClickBySlug="articleDetailOnClickBySlug"
          ></ArticleTableCellCard>
        </v-col>
      </v-row>
    </v-container>

    <div align="center">
      <v-btn
        width="40%"
        append-icon="mdi-chevron-right"
        elevation="0"
        class="ma-6 border text-caption text-black"
        height="50px"
        :ripple="false"
        rounded="pill"
        :to="{ name: 'ArticleViewList' }"
        >{{ $t("homeview.show_more") }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import Constants from "@/uitls/Constants";
import ArticleTableCellCard from "@/components/ArticleTableCellCard.vue";

export default {
  components: {
    ArticleTableCellCard,
  },

  data() {
    return {
      list: undefined,
      no_IMAGE: Constants.NO_IMAGE,
    };
  },

  async mounted() {
    this.list = await this.$store.dispatch(
      "ArticleStore/getWordPressTop8Article"
    );

    await this.getWordPressCategories({
      per_page: 100,
    });
  },

  methods: {
    articleIDCallBack(articleID) {
      this.$router.push({
        name: "ArticleViewDetail",
        params: { id: articleID },
      });
    },
    articleDetailOnClickBySlug(articleSlug) {
      this.$router.push({
        name: "ArticleViewDetailBySlug",
        params: { article_slug: articleSlug },
      });
    },

    getWordPressCategories(params) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("ArticleStore/getWordPressCategories", params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            throw new Error(error);
          });
      });
    },
  },
};
</script>

<style scoped>
.text-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
</style>


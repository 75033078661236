<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    max-width="1200"
    width="100%"
    elevation="0"
  >
    <br />

    <v-list-item class="mt-0">
      <template v-slot:prepend>
        <v-img src="/discounts.png" :width="30"></v-img>
      </template>
      <div class="d-flex align-center justify-space-between">
        <h2 class="text-h5 mx-2" style="font-weight: bold">
          {{ $t(pageTitle) }}
        </h2>
      </div>
    </v-list-item>

    <div v-if="list?.length > 0">
      <v-container v-if="isMobileDevice.value">
        <v-card
          class="event-card mb-4"
          v-for="(item, index) in list"
          :key="index"
          height="140"
          :ripple="false"
          @click="cardOnClick(index)"
        >
          <v-layout row>
            <v-img
              width="140"
              max-width="140"
              cover
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,1.0)"
              :lazy-src="local_logo"
              aspect-ratio="1"
              :src="item.picture_url"
              class="align-end"
              :alt="item.shop ? item.shop.name : ''"
            >
              <div class="date-ribbon">
                <p class="text-body-2" font-weight: bold>OFF</p>
                <p class="text-body-2" style="font-weight: bold">
                  {{ getDiscountRate(item) }}
                </p>
              </div>
              <p
                class="text-white text-center text-body-2 mb-2"
                style="font-weight: bold"
              >
                +{{ item.crowdfunding_items.length }}
                {{ $t("homeview.voucher") }}
              </p>
            </v-img>

            <v-layout column justify-space-between class="flex-grow-1">
              <v-row class="mx-0 mt-n4">
                <v-col cols="12">
                  <div
                    v-snip="{ lines: 1 }"
                    class="text-body-1 mt-2"
                    style="color: rgb(51, 51, 51); font-weight: bold"
                  >
                    {{ item.title }}
                  </div>
                  <p class="text-caption text-grey mt-0">
                    {{ item.shop ? item.shop.name : "-" }}
                  </p>
                  <p class="text-caption text-grey mt-n1">
                    {{ prefectureCodeToString(item) }}
                  </p>

                  <p class="mt-0 text-body-2" style="color: rgb(160, 160, 160)">
                    <span class="text-h6" style="color: #fe453e">
                      <strong
                        >RM
                        {{ item.crowdfunding_items[0].price }}
                      </strong>
                    </span>
                    &nbsp;
                    <strike>
                      RM{{ item.crowdfunding_items[0].original_price }}
                    </strike>
                  </p>

                  <p class="text-body-2" v-snip="{ lines: 2 }">
                    {{ item.crowdfunding_items[0].name }}
                  </p>
                </v-col>
              </v-row>
            </v-layout>
          </v-layout>
        </v-card>
      </v-container>

      <v-container v-else>
        <v-row no-gutters warp justify="center" class="d-flex">
          <v-col
            v-for="(item, index) in list"
            :key="index"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <v-card
              :loading="loading"
              class="ma-3"
              max-width="374"
              @click="cardOnClick(index)"
            >
              <template v-slot:loader="{ isActive }">
                <v-progress-linear
                  :active="isActive"
                  color="deep-purple"
                  height="4"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img
                cover
                height="250"
                :lazy-src="local_logo"
                aspect-ratio="1"
                :src="item.picture_url"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,1.0)"
                class="align-end"
                :alt="item.shop ? item.shop.name : ''"
              >
                <p
                  class="text-white text-center text-body-2 mb-2"
                  style="font-weight: bold"
                >
                  +{{ item.crowdfunding_items.length }}
                  {{ $t("homeview.voucher") }}
                </p>
              </v-img>
              <v-card-item>
                <v-card-title>
                  <strike class="text-body-2">
                    RM{{ item.crowdfunding_items[0].original_price }} </strike
                  >&nbsp;
                  <strong class="text-h4 text-red" style="font-weight: bold">
                    RM{{ item.crowdfunding_items[0].price }}
                  </strong>
                  <v-chip
                    class="mx-1 mt-n3 text-body-1"
                    color="red"
                    text-color="white"
                  >
                    {{ getDiscountRate(item) }}
                  </v-chip>

                  <div class="mt-1 text-h6" style="font-weight: bold">
                    <v-icon
                      icon="mdi-sale"
                      size="24"
                      color="red"
                      class="mt-n1"
                    ></v-icon>
                    {{ $t("crowdfunding.available_voucher") }}
                    {{ item.crowdfunding_items[0].amount }}
                  </div>
                </v-card-title>

                <v-card-subtitle class="mt-2">
                  <div class="mt-1 text-body-2" style="font-weight: normal">
                    <v-icon
                      icon="mdi-store"
                      size="16"
                      color="black"
                      class="mt-n1"
                    ></v-icon>
                    {{ item.shop.name }}
                  </div>
                  <div class="mt-0">
                    <v-icon
                      icon="mdi-map-marker"
                      size="16"
                      color="black"
                      class="mx-0 mt-n1"
                    />
                    <span class="mx-2" style="font-size: 12px">
                      {{ prefectureCodeToString(item) }}
                    </span>
                  </div>
                </v-card-subtitle>
              </v-card-item>

              <v-card-text>
                <p class="text-body-1" style="font-weight: bold">
                  {{ item.title }}
                </p>
                <p class="text-body-2" v-snip="{ lines: 2 }">
                  {{ item.description }}
                </p>
              </v-card-text>
            </v-card>

            <!-- With time limit design -->
            <!-- <v-card
            :loading="loading"
            class="mx-auto my-12"
            max-width="374"
            :ripple="false"
            @click="onClick()"
          >
            <template v-slot:loader="{ isActive }">
              <v-progress-linear
                :active="isActive"
                color="deep-purple"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img
              cover
              height="250"
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>

            <v-card-item>
              <v-card-title
                ><strike>RM70</strike>&nbsp;
                <strong>RM50</strong>
                <v-chip class="mx-4" color="green" text-color="white">
                  20% OFF
                </v-chip>
              </v-card-title>

              <v-card-subtitle>
                <div class="mt-1 text-body-2" style="font-weight: normal">
                  <v-icon
                    icon="mdi-store"
                    size="16"
                    color="black"
                    class="mt-n1"
                  ></v-icon>
                  Tokyo Beauty Salon
                </div>
                <div class="mt-0">
                  <v-icon
                    icon="mdi-map-marker"
                    size="16"
                    color="black"
                    class="mx-0 mt-n1"
                  />
                  <span class="mx-2" style="font-size: 12px"
                    >KL Pavilion Mall</span
                  >
                </div>
              </v-card-subtitle>
            </v-card-item>

            <v-card-text>
              <div class="text-body-1">
                【JAXA・東レ共同開発宇宙技術搭載】無敵のTシャツムーンテックVネックTEE
              </div>

              <v-row no-gutters warp class="d-flex mt-2">
              <div class="mt-1 text-h6" style="font-weight: normal">
                <v-icon
                  icon="mdi-clock-outline"
                  size="24"
                  color="black"
                  class="mt-n1"
                ></v-icon>
                22 days
              </div>
              <v-progress-linear
                class="mt-2"
                model-value="20"
                height="25"
                color="green"
                rounded
              >
                <strong>20%</strong>
              </v-progress-linear>

              <div class="mt-2">
                <v-chip-group v-model="selection">
                  <v-chip>Facial</v-chip>
                  <v-chip>Hair</v-chip>
                  <v-chip>Nail</v-chip>
                </v-chip-group>
              </div>
            </v-row>

            </v-card-text>
          </v-card> -->
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col class="text-center">
            <v-btn
              width="40%"
              elevation="0"
              append-icon="mdi-chevron-right"
              class="ma-6 border text-caption text-black"
              height="50px"
              :ripple="false"
              rounded="pill"
              @click="showMoreOnClick"
              >{{ $t("homeview.show_more") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-container
        ><p class="text-grey text-body-2" style="font-weight: normal">
          {{ $t("crowdfunding.no_voucher_at_this_moment") }}
        </p></v-container
      >
    </div>
  </v-sheet>
</template>

<script scoped>
import Uitls from "@/uitls/Uitls.js";

export default {
  props: ["pageTitle", "isCrowdfoundingDetail", "showItemCount"],

  data() {
    return {
      list: [],
      local_logo: require("@/assets/img/logo120.png"),
    };
  },
  beforeMount() {},

  async mounted() {
    this.loadMoreVoucher(this.showItemCount);
  },

  methods: {
    onClick() {},

    async loadMoreVoucher(id) {
      this.list = [];
      const params = {
        page: 1,
        per_page: id,
        sort: "-id",
      };

      await this.$store
        .dispatch("crowdStore/fetchCrowds", params)
        .then((response) => {
          this.list = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    prefectureCodeToString(item) {
      let result = null;
      if (item.shop && item.shop.prefecture_v2) {
        result = this.$i18n.t("prefecture." + item.shop.prefecture_v2.code);
      }
      return result;
    },

    cardOnClick(index) {
      const targetItem = this.list[index];
      if (targetItem) {
        this.$router.replace({
          name: "CrowdfundingsDetail",
          params: { crowdfundingId: targetItem.id },
        });
      }
    },

    showMoreOnClick() {
      this.$router.push({
        name: "SearchVoucherResult",
        query: { query: null },
      });
    },

    getDiscountRate(item) {
      return item.crowdfunding_items[0].discount_rate.toFixed(0) + "%";
    },
  },
};
</script>

<style scoped>
/* https://noahbres.github.io/blog/bonfire-devlog-3-how-to-make-an-events-card-list */
.event-card .desc {
  margin-bottom: 1.2em;
  font-size: 0.16em;
  padding-left: 0.1em;
}

.event-card .date-ribbon {
  position: absolute;
  top: 0px;
  left: 1em;
  background: #fe453e;
  color: #fff;
  padding: 0em 0.3em;
  padding-bottom: 0px;
  border-radius: 0px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.event-card .date-ribbon::before,
.event-card .date-ribbon::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 0%;
  height: 0px;
}

.event-card .date-ribbon::before {
  left: 0;
  border-left: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-right: solid 2em transparent;
}

.event-card .date-ribbon::after {
  right: 0;
  border-right: solid 2em #fe453e;
  border-top: solid 0px #fe453e;
  border-bottom: solid 10px transparent;
  border-left: solid 2em transparent;
}
</style>

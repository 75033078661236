<template>
  <div align="center" class="mt-4">
    <v-row justify="center" no-gutters class="">
      <v-col cols="12" class="">
        <v-btn-toggle
          v-model="selected"
          rounded="xl"
          color="deep-purple-accent-3"
          group
          density="compact"
          variant="outlined"
          mandatory
          @click="change(selected)"
        >
          <v-btn value="" size="small" :ripple="false">{{
            $t("shop.all")
          }}</v-btn>
          <v-btn value="shop_work" size="small" :ripple="false">{{
            $t("shop.work")
          }}</v-btn>
          <v-btn value="shop_interior" size="small" :ripple="false">{{
            $t("shop.shop")
          }}</v-btn>
          <v-btn value="shop_other" size="small" :ripple="false">{{
            $t("shop.other")
          }}</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </div>

  <v-row no-gutters warp justify="center" class="mt-10">
    <v-col
      v-for="(image, index) in shopImages"
      :key="image.id"
      class="d-flex child-flex"
      :cols="this.isMobileDevice.value ? 4 : 3"
    >
      <v-img
        :src="image.picture_url_small"
        :lazy-src="image.picture_url_small"
        aspect-ratio="1"
        cover
        class="bg-grey-lighten-2"
        @click="imagePreviewClick(index)"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey-lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>

  <v-dialog
    v-model="fullImage"
    :max-width="isMobileDevice.value ? '100%' : '60%'"
  >
    <Carousel
      :starting-image="imagePreviewPosition"
      :images="shopPicturesForPreviewList"
    />
  </v-dialog>
</template>

<script>
import Carousel from "@/uitls/Carousel.vue";

export default {
  components: {
    Carousel,
  },

  props: ["id"],

  setup(props) {
    console.log(props.id);
  },

  data() {
    return {
      selected: 0,
      shopImages: [],
      local_logo: require("@/assets/img/logo120.png"),
      fullImage: false,
      currentImageIndex: -1,
      previewImage: null,
      shopPicturesForPreviewList: [],
      imagePreviewPosition: 0,
    };
  },

  mounted() {
    this.getShopPictures("");
  },

  methods: {
    change(val) {
      this.getShopPictures(val);
    },

    imagePreviewClick(index) {
      (this.fullImage = true), (this.imagePreviewPosition = index);
    },

    async getShopPictures(value) {
      const params = {
        id: this.id,
        category: value ? value : "",
      };

      await this.$store
        .dispatch("shopStore/fetchShopPictures", params)
        .then((response) => {
          this.shopImages = response;

          this.shopPicturesForPreviewList = [];
          for (let index = 0; index < this.shopImages.length; index++) {
            this.shopPicturesForPreviewList.push({
              id: index,
              big: this.shopImages[index].picture_url,
              thumb: this.shopImages[index].picture_url_small,
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },

  computed: {},
};
</script>

<style>
</style>
